<template>
  <div class="container">
    <div
      v-if="pageDataLoaded === null"
      class="text-center"
    >
      <font-awesome-icon
        icon="spinner"
        spin
      />
    </div>
    <div v-if="pageDataLoaded">
      <h1 class="d-none d-md-block">
        {{ pageData.name }}
      </h1>
      <section v-if="pageData.childCategories.length > 0">
        <h2>Subcategories</h2>
        <ul>
          <li
            v-for="category in pageData.childCategories"
            :key="category.categoryId"
          >
            <router-link :to="createCategoryRoute(category)">
              {{ category.name }}
            </router-link>
          </li>
        </ul>
      </section>
      <section v-if="pageData.procedures.length > 0">
        <h2>Procedures</h2>
        <ul>
          <li
            v-for="procedure in pageData.procedures"
            :key="procedure.procedureId"
          >
            <router-link :to="createProcedureRoute(procedure)">
              {{ procedure.name }}
            </router-link>
          </li>
        </ul>
      </section>
    </div>
    <div v-if="pageDataLoaded === false">
      <div class="alert alert-warning">
        Page data did not load.
      </div>
    </div>
  </div>
</template>

<script>
import { mutationTypes as procedureCategoryMutationTypes } from '../../store/modules/procedure-category'
import { getProcedureCategory } from '../../lib/api'
import { PROCEDURE_DETAIL_PAGE } from '../../router/routes.types'
import pageMixin from '../../lib/mixins/page'

export default {
  name: 'ProcedureCategory',
  components: {},
  mixins: [pageMixin],
  computed: {
    pageData () {
      return this.$store.state.procedureCategory.pageData
    }
  },
  watch: {
    async $route (v, ov) {
      await this.loadPageData()
    }
  },
  async mounted () {
    await this.loadPageData()
  },
  methods: {
    async loadPageData () {
      this.pageDataLoaded = null

      try {
        const response = await getProcedureCategory(this.$route.params.categoryId)

        this.$store.commit(procedureCategoryMutationTypes.PROCEDURE_CATEGORY__SET_PAGE_DATA, response.data)

        this.pageDataLoaded = true
      } catch {
        this.pageDataLoaded = false
      }
    },
    createProcedureRoute (procedure) {
      return {
        name: PROCEDURE_DETAIL_PAGE,
        params: {
          procedureId: procedure.procedureId
        }
      }
    }
  }
}
</script>

<style>

</style>
